<template>
  <div
    class="container sticky top-0 z-10 p-4 mx-auto shadow-sm max-w-screen-2xl"
    style="background: rgba(255, 255, 255, 0.5); backdrop-filter: blur(5px)"
  >
    <div
      id="topNav"
      class="
        flex-row
        items-center
        justify-between
        hidden
        px-8
        mx-auto
        font-sans
        max-w-screen-2xl
        lg:flex
      "
    >
      <div class="flex flex-row items-center divide-x-2">
        <a href="https://igem.org/Main_Page">
          <img
            src="https://static.igem.io/podcast/assets/logos/iGEM_black.png"
            class="px-2 w-14"
          />
        </a>
        <router-link :to="{ name: 'Home' }">
          <img
            src="https://static.igem.io/podcast/assets/logos/igem-podcasts-logo-black.svg"
            class="w-32 mx-2"
          />
        </router-link>
      </div>
      <div class="relative w-5/12" v-on:click="showSearch = true">
        <input
          class="
            w-full
            px-8
            py-2
            text-black
            bg-gray-200
            rounded-lg
            opacity-50
            focus:outline-none
          "
          type="text"
          v-model="search"
          placeholder="Search all episodes"
        />
        <div
          v-if="showSearch"
          class="absolute w-full shadow-xl rounded-xl top-14"
          style="background: rgba(255, 255, 255, 0.95)"
        >
          <div v-if="search != ''" class="overflow-auto h-96">
            <div v-for="episode in filteredEpisodes" :key="episode.id">
              <router-link :to="'/episode/' + episode.slug">
                <div class="flex flex-row p-4 space-x-4">
                  <div class="w-1/6">
                    <img
                      :src="
                        'https://static.igem.io/podcast/assets/artwork/guests/' +
                        episode.artwork
                      "
                      class="flex-shrink-0"
                    />
                  </div>
                  <div class="w-5/6 font-serif text-lg break-words">
                    S{{ episode.season }}E{{ episode.episode }} |
                    {{ episode.name }} <br />
                    <span
                      class="
                        px-2
                        py-1
                        font-serif
                        text-sm text-white
                        bg-black
                        rounded-md
                      "
                      >{{ episode.type }}</span
                    >
                    <span
                      v-if="episode.type != 'iGEMers of The World'"
                      class="
                        px-2
                        py-1
                        ml-2
                        font-serif
                        text-sm text-white
                        bg-black
                        rounded-md
                      "
                      >{{ episode.theme }}</span
                    >
                  </div>
                  <hr />
                </div>
              </router-link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-6 text-sm font-medium">
        <div>
          <span
            ><router-link :to="{ name: 'Episodes' }"
              >All Episodes</router-link
            ></span
          >
        </div>
        <div>
          <span
            ><router-link :to="{ name: 'HashtagSynBio' }"
              >#SynBio Podcast</router-link
            ></span
          >
        </div>
        <div>
          <span
            ><router-link :to="{ name: 'iGEMersOfTheWorld' }"
              >iGEMers of The World</router-link
            ></span
          >
        </div>
        <div>
          <span
            ><router-link :to="{ name: 'GuestHosts' }"
              >Guest Hosts</router-link
            ></span
          >
        </div>
        <div>
          <span><router-link :to="{ name: 'About' }">About</router-link></span>
        </div>
      </div>
    </div>
    <div class="z-10 lg:hidden">
      <div class="flex flex-row items-center justify-between space-x-4">
        <div class="flex flex-row items-center divide-x-2">
          <a href="https://igem.org/Main_Page">
            <img
              src="https://static.igem.io/podcast/assets/logos/iGEM_black.png"
              class="px-2 w-14"
            />
          </a>
          <router-link :to="{ name: 'Home' }">
            <img
              src="https://static.igem.io/podcast/assets/logos/igem-podcasts-logo-black.svg"
              class="w-32 px-2"
            />
          </router-link>
        </div>
        <div class="text-xl hamburger-icon" @click="toggle()">&#9776;</div>
      </div>
      <div
        v-if="active"
        class="
          inset-0
          flex flex-col
          h-screen
          py-12
          space-y-12
          font-sans
          text-base
          font-light
          text-center
        "
      >
        <span
          ><router-link :to="{ name: 'Episodes' }"
            >All Episodes</router-link
          ></span
        >
        <span
          ><router-link :to="{ name: 'HashtagSynBio' }"
            >#SynBio Podcast</router-link
          ></span
        >
        <span
          ><router-link :to="{ name: 'iGEMersOfTheWorld' }"
            >iGEMers of The World</router-link
          ></span
        >
        <span
          ><router-link :to="{ name: 'GuestHosts' }"
            >Guest Hosts</router-link
          ></span
        >
        <span><router-link :to="{ name: 'About' }">About</router-link></span>
      </div>
    </div>
  </div>
</template>

<script>
import allEpisodes from "../data/allEpisodes";

export default {
  name: "TopNav",
  data: function () {
    return {
      active: false,
      allEpisodes: allEpisodes,
      showSearch: false,
      search: "",
    };
  },
  computed: {
    currentRoute: function () {
      return this.$route.name;
    },
    filteredEpisodes: function () {
      return this.allEpisodes.filter((episode) => {
        return episode.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },
  methods: {
    toggle: function () {
      this.active = !this.active;
    },
  },
};
</script>
