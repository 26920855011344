const hosts = [
  {
    name: "Zeeshan Siddiqui",
    title: "Host, Director of iGEM Podcasts",
    paragraphTitle: "Zeeshan's iGEM Journey",
    description:
      "I completed my undergraduate degree in Molecular Biology and Bioinformatics from UNSW, Australia. I was part of the protein engineering team for the 2018 UNSW iGEM and the president of the UNSW Biotechnology society in 2019. I returned to the iGEM world in 2020 as the Australian Ambassador and launched the iGEM Podcasts initiative. Growing up, the three TV shows I would watch on repeat were Mythbusters, followed by medical shows on NatGeo and Animal Planet. It’s fair to say that I had a very diverse range of interests. Synthetic biology gave me the opportunity to find direction within my career. Never had I come across a field so multidisciplinary and with extraordinary potential. The era of SynBio has dawned and it's time to tell its story - of the past, present and the future!",
    linkedin: "http://www.linkedin.com/in/igempodcasts-zee-076710212",
    twitter: "https://twitter.com/Roaming_Sci_Zee",
    region: "Asia & Oceania",
    country: "Australia",
    image: "zeeshan-illustration.svg",
  },
  {
    name: "Melody Wu",
    title: "Co-host, 2020 After iGEM Ambassador to North America",
    paragraphTitle: "Melody's iGEM Journey",
    description:
      "I’m an undergraduate majoring in Biological Engineering at MIT originally from the Los Angeles Area in California. I’m really passionate about exploring the interdisciplinary, in particular at the intersection of planetary health, synthetic biology/biotech, and art. I love creating art to communicate science concepts, particularly the amazing world of synthetic biology, in an easy and fun way but also just to create surreal art. My iGEM journey started the summer after my second year at Alverno Heights Academy (2016 iGEM Alverno_CA). Our project was focused on reducing the effects of supercoiling in multi-genetic circuits to allow for predictable gene expression in bacteria. n 2019, I continued my iGEM journey as part of the MIT iGEM team, where our project was focused on directing neutrophil-like cell migration along a chemokine gradient secreted by engineered HEK cells.",
    linkedin: "https://www.linkedin.com/in/melody-wu-175ba5159",
    twitter: "https://twitter.com/mwumusic2001",
    region: "North America",
    country: "United States of America",
    image: "melody-illustration.svg",
  },
  {
    name: "Shrestha Rath",
    title:
      "2020 Regional Ambassador Program Coordinator (Asia & Oceania)",
    paragraphTitle: "Shrestha's iGEM Journey",
    description:
      "Shrestha is the Regional Program Coordinator at After iGEM, where she led the team of iGEM Ambassadors from Asia & Oceania. She graduated with a Master's in Biological Sciences and has been awarded the KVPY Fellowship by the Department of Science and Technology, Government of India. Apart from science fiction, her interests lie in using synthetic biology to monitor human and environmental health.",
    linkedin: "https://www.linkedin.com/in/shresrath",
    twitter: "https://twitter.com/shresrath",
    region: "Asia",
    country: "India",
    image: "shrestha-illustration.svg",
  },
];

export default hosts;
