<template>
  <div class="flex flex-row py-4 justify-evenly">
    <div v-if="podcast.google != ''">
      <a :href="podcast.google" target="_blank"
        ><img
          src="https://static.igem.io/podcast/assets/badges/google-podcasts-badge.png"
          class="h-10 transition duration-300 transform hover:scale-110"
      /></a>
    </div>
    <div v-if="podcast.spotify != ''">
      <a :href="podcast.spotify" target="_blank"
        ><img
          src="https://static.igem.io/podcast/assets/badges/spotify-badge.svg"
          class="h-10 transition duration-300 transform hover:scale-110"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Platforms",
  props: {
    podcast: {
      type: Object,
      required: true,
    },
  },
};
</script>
