const topics = [
  {
    title: "Computational SynBio",
    soonBlurb: "Computing episodes soon...",
    id: 1,
    bgClass: "bg-fmBlue ",
    art: "computational.svg",
    pattern: "computational-pattern.png",
    theme: "Computation",
  },
  {
    title: "Educational SynBio",
    soonBlurb: "Building dialogues soon...",
    id: 2,
    bgClass: "bg-fmYellow ",
    art: "educational.svg",
    pattern: "educational-pattern.png",
    theme: "Education",
  },
  {
    title: "Entrepreneurial SynBio",
    soonBlurb: "Synergizing dialogues soon...",
    id: 3,
    bgClass: "bg-fmPink ",
    art: "entrepreneurial.svg",
    pattern: "entrepreneurial-pattern.png",
    theme: "Entrepreneurship",
  },
  {
    title: "Research SynBio",
    soonBlurb: "Conducting discussions soon...",
    id: 4,
    bgClass: "bg-fmGreen ",
    art: "research.svg",
    pattern: "research-pattern.png",
    theme: "Research",
  },
  {
    title: "Arts & Design SynBio",
    soonBlurb: "Crafting conversations soon...",
    id: 5,
    bgClass: "bg-fmPurple ",
    art: "arts.svg",
    pattern: "arts-pattern.png",
    theme: "Arts & Design",
  },
];

export default topics;
