<template>
  <div
    class="grid justify-around grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3"
  >
    <div v-for="topic in topics" :key="topic.id">
      <div class="p-16" :class="topic.bgClass" data-aos="zoom-in">
        <a :href="'#' + topic.id">
          <img
            :src="'https://static.igem.io/podcast/assets/logos/' + topic.art"
            class="w-32 h-32 mx-auto sm:w-48 sm:h-48"
          />
          <div
            class="
              absolute
              inset-0
              flex
              items-center
              justify-center
              text-center text-white
              transition
              duration-300
              bg-black
              opacity-70
              hover:opacity-0
              xs-headline
            "
          >
            {{ topic.title }}
          </div>
        </a>
      </div>
    </div>
    <div class="p-16" data-aos="zoom-in">
      <a href="#latest">
        <img
          src="https://static.igem.io/podcast/assets/logos/htsb.svg"
          class="w-32 h-32 mx-auto sm:w-48 sm:h-48"
        />
        <div
          class="
            absolute
            inset-0
            flex
            items-center
            justify-center
            text-center text-white
            transition
            duration-300
            bg-black
            opacity-70
            hover:opacity-0
            xs-headline
          "
        >
          Latest Episode
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import topics from "../data/topics.js";
export default {
  name: "HashtagSynBioThemes",
  data() {
    return {
      topics: topics,
    };
  },
};
</script>
