const allEpisodes = [
  {
    id: 22,
    type: "#SynBio Podcast",
    name: "Bio. Economy. Innovation",
    guest: "Kathryn Hamilton",
    date: "Dec 24, 2021",
    season: 1,
    episode: 9,
    blurb:
      "Kathryn Hamilton is the Managing editor and senior staff writer at Bioeconomy.XYZ. As well as Senior Consultant, (Biomedical Positioning) at Booz Allen Hamilton. She believes that Biotechnology is humanity’s highest art form. In this episode, Shrestha sits down with Kathryn to discuss:\n\n-How Kathryn’s previous scientific training has helped her beyond the traditional realms of research and academia.\n-How the creation of local science ecosystems can help establish greater trust between science and society.\n-The current and future state of the Bioeconomy.",
    theme: "Education",
    artwork: "htsb-kathryn.png",
    apple:
      "https://podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000546064526",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000546064526",
    google: "",
    spotify:
      "https://open.spotify.com/episode/4LbuRtyJga7edlE3F0wwK4?si=bf3ea0debf6d4f88",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e9",
  },
  {
    id: 21,
    type: "#SynBio Podcast",
    name: "iGEM. Tackles. SDGs",
    guest: "Todd Kuiken",
    date: "Oct 5, 2021",
    season: 1,
    episode: 8,
    blurb:
      "Todd Is a senior research scholar and executive committee member at NC states genetic engineering and society center. His work explores the scientific and technological frontier, stimulating discovery and bringing new tools to bear on public policy challenges that emerge as science advances.\nHe has been appointed to the International Union for Conservation of Nature’s (IUCN) technical and policy task force on synthetic biology and gene drives, which culminated in the first comprehensive assessment of the impacts of synthetic biology and gene drives on conservation. In 2020, he was appointed to the U.N. Convention on Biological Diversity’s Ad-Hoc-Technical Expert Group (AHTEG) on synthetic biology.\n\nTood plays a very active role at iGEM, as a long time judge and by serving as the co-chair of its sustainable development goals program and as the former co-chair of the human practices program.\n\nIn today's episode we discuss:\n-Bringing the iGEM SDG working group to life.\n-The first few questions iGEMers should ask themselves when trying to develop an SDG project. \n-The intense, controversial and hopeful conversations taking place at the UN and within the convention of biological diversity.",
    theme: "Education",
    artwork: "htsb-todd.png",
    apple:
      "https://podcasts.apple.com/au/podcast/s1e8-igem-tackles-sdgs-todd-kuiken-chats-with-zeeshan/id1566549358?i=1000537661072",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s1e8-igem-tackles-sdgs-todd-kuiken-chats-with-zeeshan/id1566549358?i=1000537661072",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/MDhkMWY2MDItZjQ4Yi00MTdjLWEzYmUtYjcxMjFkOWY2MDBk?sa=X&ved=0CAUQkfYCahcKEwigj7LRurXzAhUAAAAAHQAAAAAQCg",
    spotify:
      "https://open.spotify.com/episode/7k8aZa147kJbHlvNGDTPqI?si=mr-NxL97S3qUP5oKSe1HTw&dl_branch=1",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e8",
  },
  {
    id: 20,
    type: "#SynBio Podcast",
    name: "Life. Science. Venture",
    guest: "Miroslav Gasparek",
    date: "Sep 22, 2021",
    season: 1,
    episode: 7,
    blurb:
      "Miroslav Gasparek is a PhD candidate in engineering science at Oxford University. He’s also a venture fellow at Civilisation ventures, and part of the steering committee at the European Union SynBio society.\n\nMiro’s research lies at the intersection of SynBio, control theory and data-driven control methods like reinforcement learning. He’s interested in the practical applications of SynBio, its wider impact and providing solutions to real-life problems. As well as understanding how meaningful and live-saving innovations get to the market.\n\nTopics explored in this week's episode\n\n-How does a life scientist get interested in Venture Capital?\n\n-How to formally analyse an investment opportunity in Synthetic Biology\n\n-Why are there more challenges for raising capital for SynBio companies in Europe when compared to the US?\n\n-Miro’s research journey as well as Fellowship experience at Newton Ventures and Civilisation Ventures.\n\nMiro's website: https://www.miroslavgasparek.com/pages/about.html",
    theme: "Entrepreneurship",
    artwork: "htsb-miro.jpg",
    apple:
      "https://podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000536198853",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000536198853",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/NThhMmJlZTItMDYwMC00OTk5LThkMjctZmFlNjUxNWRjYTEy?sa=X&ved=0CAUQkfYCahcKEwiYrr-1gJHzAhUAAAAAHQAAAAAQAQ",
    spotify:
      "https://open.spotify.com/episode/01iIJZPV9yEfuJifm5Dhkm?si=uGLffd1sS56WPS3uB8siPA&dl_branch=1",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e7",
  },
  {
    id: 19,
    type: "#SynBio Podcast",
    name: "Research. Future. Marketplace",
    guest: "Drew Endy",
    date: "Aug 31, 2021",
    season: 1,
    episode: 6,
    blurb:
      "Drew has served on the US National Science Advisory Board for Biosecurity and the Committee on Science, Technology, & Law; he currently serves on the World Health Organization’s Smallpox Advisory Committee. Esquire magazine recognized Drew as one of the 75 most influential people of the 21st century.\n\nTopics discussed in todays episode:\n-Does the most transformative SynBio research lie outside academia?\n-The cultural, political and economic systems that will enable SynBio development in the years ahead.\n-What is the key vulnerability of a for-profit organisation?\n-How can we engineer biology with evolution?",
    theme: "Research",
    artwork: "htsb-drew.jpg",
    apple:
      "https://podcasts.apple.com/podcast/s1e6-research-future-marketplace-drew-endy-chats-with/id1566549358?i=1000533843604",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s1e6-research-future-marketplace-drew-endy-chats-with/id1566549358?i=1000533843604",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/NWYyMGU0MDQtNDY0NS00MTZmLWE5MWYtYTBmNWI5YTZkNzlh?sa=X&ved=0CAUQkfYCahcKEwiAy-W4n9vyAhUAAAAAHQAAAAAQAQ",
    spotify:
      "https://open.spotify.com/episode/4A4jFT8byBydlSlcUdFQiQ?si=d51e6475c06d4024",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e6",
  },
  {
    id: 18,
    type: "#SynBio Podcast",
    name: "Decoding. The. World",
    guest: "Arvind Gupta and Po Bronson",
    date: "Aug 17, 2021",
    season: 1,
    episode: 5,
    blurb:
      "Is climate change opening the floodgates of the germ zoo? Are Silicon Valley investors understanding and accepting climate change? In this episode, Zeeshan chats with Arvind Gupta and Po Bronson about their book, Decoding The World, where they try and decode the real cause of the pandemic, climate change, iGEM and SynBio & Silicon Valley!\n\nArvind is the founder and a venture advisor at IndieBio, which is the world’s first and leading accelerator dedicated to startups in biology to solve the world’s problems. As founder, Arvind has redefined the possibilities of early stage biotech, investing in over 136 companies over five years and growing the IndieBio portfolio into billions of dollars in value. His current role is at the global VC firm, Mayfield,  where he co-leads the engineering biology practice whose mission is to invest in science based companies that could change history. Po Bronson was a finance and tech journalist covering Silicon Valley for Wired, The New York Times Magazine, and an op-ed contributor for The Wall Street Journal. His science journalism has been honored with nine national awards, and he is the author of seven bestselling books that are available in 28 languages worldwide. Po is currently the Managing Director of IndieBio and General Partner at SOSV, the venture firm that backs IndieBio.",
    theme: "Entrepreneurship",
    artwork: "htsb-decoding_the_world.jpg",
    apple:
      "https://podcasts.apple.com/au/podcast/s1e5-decoding-the-world-zeeshan-chats-with-arvind-gupta/id1566549358?i=1000532250738",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s1e5-decoding-the-world-zeeshan-chats-with-arvind-gupta/id1566549358?i=1000532250738",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/ZjU1MTc1ZTMtZTNhMi00NmVlLWI3YjktZWU5ZDQ0YzkyZGYy?sa=X&ved=0CAUQkfYCahcKEwjIpaC71rfyAhUAAAAAHQAAAAAQGA",
    spotify:
      "https://open.spotify.com/episode/4gltQbnvVtEMtRy0LHWwUi?si=4e51c1fff1ff4274",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e5",
  },
  {
    id: 17,
    type: "#SynBio Podcast",
    name: "AlphaFold. 2. Explained. Zeeshan chats with Charlie Harris.",
    guest: "Charlie Harris",
    date: "Aug 2, 2021",
    season: 1,
    episode: 4,
    blurb:
      "DeepMind’s work and success in protein structure prediction shows how exciting of a time it is to be part of biology and science in general. AlphaFold 2 is revolutionary, and to help me understand the science behind it, I'm joined by Charlie Harris. Charlie is currently finishing off his masters degree in Bioinformatics and Theoretical Systems Biology at Imperial College London, where he is working with Prof Michael Bronstein looking into generative models for structure based drug design. Charlie is also the chair and founder of the Imperial College Computational Biology Society. He's also about to start a PhD at the University of Cambridge Centre for AI in Medicine.\n\nLinks:\n-AlphaFold 2 Nature paper: https://www.nature.com/articles/s41586-021-03819-2\n-AlphaFold 2 and the EMBL-EBI database Nature paper: https://www.nature.com/articles/s41586-021-03828-1\n-Oxford Protein Informatics Group blog: https://www.blopig.com/blog/2021/07/alphafold-2-is-here-whats-behind-the-structure-prediction-miracle/\n-Mohammed AlQuraishi AlphaFold 2 blog post: https://moalquraishi.wordpress.com/2021/07/25/the-alphafold2-method-paper-a-fount-of-good-ideas/amp/\n-Cambridge Centre for AI in Medicine website: https://ccaim.cam.ac.uk/",
    theme: "Computation",
    artwork: "htsb-alphafold.jpg",
    apple:
      "https://podcasts.apple.com/au/podcast/s1e4-alphafold-2-explained-zeeshan-chats-charlie-harris/id1566549358?i=1000530644182",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s1e4-alphafold-2-explained-zeeshan-chats-charlie-harris/id1566549358?i=1000530644182",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/N2Y3NmZhZTctMjI3ZC00YTM5LWIwMTAtOWVlZGM0OTA3Yjcw?sa=X&ved=0CAUQkfYCahcKEwjA--Sn3o_yAhUAAAAAHQAAAAAQGA",
    spotify:
      "https://open.spotify.com/episode/3NJJiPVg3wWJFNO1cmis0o?si=47e31fc7254b43a1",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e4",
  },
  {
    id: 16,
    type: "iGEMers of The World",
    name: "iGEM: A crash course in research? Melody chats with Malik George",
    guest: "Malik George",
    date: "Jul 20, 2021",
    season: 2,
    episode: 4,
    blurb:
      "Malik is a Junior at MIT (class of 2022), majoring in Biogengineering and and minoring in African and African diaspora studies. Malik, alongside his brother Miles, work as admissions ambassadors to help underrepresented students. They are also involved in several Office of Minority Education programs, and were part of the 2019 MIT iGEM team. Him and his brother are stars on Tik Tok, with 59.5K followers! @malikandmiles.",
    theme: "iGEMers of The World",
    artwork: "iotw-malik.png",
    apple:
      "https://podcasts.apple.com/au/podcast/s2e4-igem-crash-course-in-research-melody-chats-malik/id1552595526?i=1000529432332",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s2e4-igem-crash-course-in-research-melody-chats-malik/id1552595526?i=1000529432332",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaWdlbWVycy1vZi10aGUtd29ybGQv/episode/MTg3Y2MwYzYtYzY5MS00MjhjLTlhZTUtNWYwYmE5NTlhMjlm?sa=X&ved=0CAUQkfYCahcKEwi4oqad_fDxAhUAAAAAHQAAAAAQDw",
    spotify:
      "https://open.spotify.com/episode/26g43VWcPajZcB2FNTf6gB?si=186b7e4732e44ebe",
    anchor: "https://anchor.fm/igem",
    ximalaya: "",
    slug: "iotw-s2e4",
  },
  {
    id: 15,
    type: "iGEMers of The World",
    name: "iGEM: The bridge between science and society? Melody chats with Megan Palmer.",
    guest: "Megan Palmer",
    date: "Jul 13, 2021",
    season: 2,
    episode: 3,
    blurb:
      "Megan Palmer is an Adjunct Professor (Bioengineering) and the Executive Director of Bio Policy & Leadership Initiatives at Stanford University. In this episode, Melody Wu chats with Megan on how her research, education and public policy programs are exploring how biological science and engineering is shaping our society. They also talk about the birth of iGEM Human Practices and how innovation in iGEM must serve public interest.",
    theme: "iGEMers of The World",
    artwork: "iotw-megan.png",
    apple:
      "https://podcasts.apple.com/au/podcast/igem-bridge-between-science-society-melody-chats-megan/id1552595526?i=1000528707067",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/igem-bridge-between-science-society-melody-chats-megan/id1552595526?i=1000528707067",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaWdlbWVycy1vZi10aGUtd29ybGQv/episode/NDUyZGQ5MjYtYzQzYS00ODVkLWFlOWMtYTEyMWEzYjYxZmE1?sa=X&ved=0CAUQkfYCahcKEwiAt-Xew9_xAhUAAAAAHQAAAAAQGA",
    spotify:
      "https://open.spotify.com/episode/3AurMjB5FOh8IC9VdQVCiv?si=JZiiot2zS568r3mKsA14Tg&dl_branch=1",
    anchor: "https://anchor.fm/igem",
    ximalaya: "",
    slug: "iotw-s2e3",
  },
  {
    id: 14,
    type: "#SynBio Podcast",
    name: "Life. Science. Robots",
    guest: "Douglas Densmore",
    date: "Jul 6, 2021",
    season: 1,
    episode: 3,
    blurb:
      "Doug is an Associate Professor of Electrical and Computer Engineering at Boston University. His research focuses on the development of tools for the specification, design, assembly and test of synthetic biological systems. On this episode, we discuss the relationship between robots and synthetic biology. As well as if automation is going to end up making SynBio more centralised.",
    theme: "Computation",
    artwork: "htsb-doug.png",
    apple:
      "https://podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000527944105",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/synbio-podcast/id1566549358?i=1000527944105",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vaGFzaHRhZy1zeW5iaW8tcG9kY2FzdC8/episode/ZTM2ZGYxM2YtNzA1YS00NDUwLWExM2UtYTEyZWY4ZDBkYmVk?sa=X&ved=0CAUQkfYCahcKEwjg2Yu0183xAhUAAAAAHQAAAAAQCg",
    spotify:
      "https://open.spotify.com/episode/4nO6Zkince1u7qatHuyyug?si=SXAt26XvR5eMiqlPXpolGQ&dl_branch=1",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e3",
  },
  {
    id: 13,
    type: "#SynBio Podcast",
    name: "The EPIC SynBio PhD-MBA",
    guest: "Cheng Kai Lim",
    date: "Jun 15, 2021",
    season: 1,
    episode: 2,
    blurb:
      "CK is a PhD-MBA student from the National University of Singapore, where his research revolves around the field of DNA data storage. He is also the Chair of iGEM EPIC, an entrepreneurship programme under the non-profit iGEM Foundation, where he serves to catalyze the formation of startups from the multitude of projects and people that have originated or come from the iGEM Competition. On this episode I talk to him about why he decided to pursue the PhD-MBA joint program, his entrepreneurial journey and iGEM EPIC.",
    theme: "Entrepreneurship",
    artwork: "htsb-chengkai.png",
    apple:
      "https://podcasts.apple.com/au/podcast/s1e2-the-epic-synbio-phd-mba-zeeshan-chats-with-cheng-kai-lim/id1566549358?i=1000525549354",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/s1e2-the-epic-synbio-phd-mba-zeeshan-chats-with-cheng-kai-lim/id1566549358?i=1000525549354",
    google:
      "https://podcasts.google.com/?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy81OWVkZDE4Yy9wb2RjYXN0L3Jzcw%3D%3D&episode=ZTY4NDIzZTQtNmQ0NC00ZWY4LWI4ZWYtZDdmYjBiY2M1YTNj",
    spotify:
      "https://open.spotify.com/episode/7xZ7gap4DLSBDSnyL6k7xl?si=09f18d48b1f7447e",
    anchor: "",
    ximalaya: "",
    slug: "htsb-s1e2",
  },
  {
    id: 12,
    type: "iGEMers of The World",
    name: " BioBuilder and the SUIS High School iGEM team. Zeeshan chats with David Doyle.",
    guest: "David Doyle",
    date: "Jun 8, 2021",
    season: 2,
    episode: 2,
    blurb:
      "David first heard about iGEM during his travels abroad in Japan while reading Regenesis, authored by Harvard Geneticist Dr. George M. Church. Upon his return, David successfully pitched the idea to the SUIS School Board, and then developed a co-curricular activity through the BioBuilder Club, an educational curriculum invented by Dr. Natalie Kuldell of the Massachusetts Institute of Technology (MIT). Because BioBuilder teaches engineering with biology, this program serves to educate both teachers and students. The SUIS iGEM Team then emerged from the BioBuilder Club through casual conversation, and attended its first iGEM Jamboree in 2017. And in 2018, the they won the award for Best Education & Public Engagement.",
    theme: "iGEMers of The World",
    artwork: "iotw-david.jpg",
    apple:
      "https://podcasts.apple.com/au/podcast/biobuilder-suis-high-school-igem-team-zeeshan-chats/id1552595526?i=1000524611802",
    appleEmbed:
      "https://embed.podcasts.apple.com/au/podcast/biobuilder-suis-high-school-igem-team-zeeshan-chats/id1552595526?i=1000524611802",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/MzMwMDM1ZTktYTFmZi00N2I2LWJhMTEtOThmNTAzMWJkZTM0?sa=X&ved=0CAUQkfYCahcKEwjo_tGvzofxAhUAAAAAHQAAAAAQEA",
    spotify:
      "https://open.spotify.com/episode/4wabH776ac0YlJD14oA5No?si=7779f4caa3f744bc",
    anchor: "https://anchor.fm/igem",
    ximalaya: "",
    slug: "iotw-s2e2",
  },
  {
    id: 11,
    type: "#SynBio Podcast",
    name: "Ready. Set. Design",
    guest: "Karen Ingram",
    date: "May 16, 2021",
    season: 1,
    episode: 1,
    blurb:
      "Karen Ingram is a creative director and artist who has become one of the world’s leading SynBio designers -without a background in Synthetic Biology! In this episode, Zeeshan talks to Karen about her SynBio design journey, and the creative strategy behind some of her most exciting projects.",
    theme: "Arts & Design",
    artwork: "htsb-karen.jpg",
    apple:
      "https://podcasts.apple.com/us/podcast/s1e1-ready-set-design-zeeshan-chats-with-karen-ingram/id1566549358?i=1000521823556",
    appleEmbed:
      "https://embed.podcasts.apple.com/us/podcast/s1e1-ready-set-design-zeeshan-chats-with-karen-ingram/id1566549358?i=1000521823556",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy81OWVkZDE4Yy9wb2RjYXN0L3Jzcw?sa=X&ved=0CAYQrrcFahcKEwiw8cOsxc3wAhUAAAAAHQAAAAAQGA",
    spotify:
      "https://open.spotify.com/episode/5LMrUKcuQK5cQgVpUp6Te4?si=dNtMVFzhRSexUWtxtgK6Qw",
    anchor:
      "https://anchor.fm/zeeshan-siddiqui4/episodes/Ready--Set--Design-e10u3ii",
    ximalaya: "",
    slug: "htsb-s1e1",
  },
  {
    id: 10,
    type: "iGEMers of The World",
    name: " iGEM journeys and Women in STEM: Zeeshan chats with Nemira",
    guest: "Nemira Zilinskaite",
    date: "May 16, 2021",
    season: 2,
    episode: 1,
    blurb:
      "Nemira is a first year PhD candidate at the University of Cambridge, UK. She was an iGEM Ambassador (Europe) in 2020, and is leading the iGEM Women in STEM (WiS) initiative this year. Join us as we talk about her iGEM journey, her diverse interests in SynBio, and the grand ambitions of the Women in STEM initiative.",
    theme: "iGEMers of The World",
    artwork: "iotw-nemira.jpg",
    apple:
      "https://podcasts.apple.com/cr/podcast/s2e1-women-in-stem-zeeshan-chats-with-nemira/id1552595526?i=1000521887177&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/s2e1-women-in-stem-zeeshan-chats-with-nemira/id1552595526?i=1000521887177&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/YzFkMzk2YzgtNzMxOC00YTliLTllMzAtZmU4N2MyMmRmMzUw?sa=X&ved=0CAUQkfYCahcKEwiQ4rDCyYnxAhUAAAAAHQAAAAAQAQ",
    spotify:
      "https://open.spotify.com/show/0lpQNxnxXzdkFhEPh726nZ?si=mpcHMWwLRMq7afKDnGRSyA",
    anchor: "https://anchor.fm/igem",
    ximalaya: "",
    slug: "iotw-s2e1",
  },
  {
    id: 9,
    type: "iGEMers of The World",
    name: "iGEM as a life-changer and Scintia, a biotech education company bringing SynBio to latin America. ",
    guest: "Ricardo Chavez",
    date: "Jan 12, 2021",
    season: 1,
    episode: 9,
    blurb:
      "Biotechnology, including synthetic biology, has traditionally been a resource-heavy field— that’s made it particularly difficult for young students to engage in biology and STEM particularly those from underrepresented backgrounds. In addition, there’s the larger obstacle of lack of support for teachers and changing curriculum as new technologies and industries emerge. Who is trying to help bring the world of synthetic biology to future generations through supporting educators passionate about changing education and inspiring young scientists? For this final episode, I chat with Ricardo C. Chavez, founder of Scintia, a biotech education company from Latin America bringing synthetic biology and bioengineering to classrooms in the region.\r\nScintia Bio site: https://en.scintia.com/",
    theme: "iGEMers of The World",
    artwork: "iotw-ricardo.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-10-igem-as-life-changer-scintia-biotech-education/id1552595526?i=1000508038007&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-10-igem-as-life-changer-scintia-biotech-education/id1552595526?i=1000508038007&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/MmNmMTg0NjYtNWIyMi00NzM2LTgzYTktZDg3ZWJkN2EzM2Fm?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/31lVQ05huWDBRvYDdaPESy?si=9737da61a12545ec",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-10-iGEM-as-a-life-changer-and-Scintia--a-biotech-education-company-bringing-synbio-to-Latin-America--Melody-chats-with-Ricardo-Chavez--founder-of-Scintia-eoqfkg/a-a4ap0gt",
    ximalaya: "",
    slug: "iotw-s1e10",
  },
  {
    id: 8,
    type: "iGEMers of The World",
    name: "“Great teachers are great learners”, BioBuilder and community in the classroom and beyond. Melody chats with Natalie Kuldell",
    guest: "Natalie Kuldell",
    date: "Jan 5, 2021",
    season: 1,
    episode: 8,
    blurb:
      "Biotechnology, including synthetic biology, has traditionally been a resource-heavy field— that’s made it particularly difficult for young students to engage in biology and STEM particularly those from underrepresented backgrounds. In addition, there’s the larger obstacle of lack of support for teachers and changing curriculum as new technologies and industries emerge. Who is trying to help bring the world of synthetic biology to future generations through supporting educators passionate about changing education and inspiring young scientists? In the next few episodes, we'll talk to three organizations in this space of biotech education and accessibility, fueled by similar values as iGEM. For this second episode, Melody chats with Dr. Natalie Kuldell, founder of the BioBuilder Foundation, a non-profit organization changing the landscape of teaching synthetic biology and bioengineering in the classroom.\r\nBioBuilder Foundation site: https://biobuilder.org/  BioBuilder Page for Students featuring activities like Eau that Smell’s banana-smelling bacteria and more: https://biobuilder.org/education/for-students/ Life-Changing Science: The BioBuilder Podcast hosted by Zeeshan:\r\nhttps://biobuilder.org/biobuilder-podcast/",
    theme: "iGEMers of The World",
    artwork: "iotw-natalie.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-9-great-teachers-are-great-learners-biobuilder/id1552595526?i=1000508037824&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-9-great-teachers-are-great-learners-biobuilder/id1552595526?i=1000508037824&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/M2E4MDExNDEtMmRjMy00NzdjLTkwZGEtZTI2M2U4M2ViOWJm?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/713FClFUvHygWke97HBGcN?si=0d03efd390bc4b83",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-9-Great-teachers-are-great-learners--BioBuilder-and-community-in-the-classroom-and-beyond--Melody-chats-with-Natalie-Kuldell--founder-of-the-BioBuilder-Foundation-eog97q/a-a48qsfp",
    ximalaya: "",
    slug: "iotw-s1e9",
  },
  {
    id: 7,
    type: "iGEMers of The World",
    name: "Biotech and SynBio education, accessibility, and BioBits. Melody chats with Ally",
    guest: "Ally Huang",
    date: "Dec 29, 2020",
    season: 1,
    episode: 7,
    blurb:
      "Biotechnology, including synthetic biology, has traditionally been a resource-heavy field— that’s made it particularly difficult for young students to engage in biology and STEM particularly those from underrepresented backgrounds. In addition, expensive biotech equipment and lack of funding in education have also made access to knowledge and the ability to engineer biological products— whatever they may be— expensive and difficult. Who are the companies and organizations trying to break down these barriers to create more accessible biotech and life sciences tools? In the next few episodes, we'll talk to three organizations in this space of biotech education and accessibility, fueled by similar values as iGEM. For this first episode, my guest is Ally Huang, creator of BioBits and part of the team at miniPCR, looking to increase accessibility to biotech technology and tools particularly for the classroom.\nTo find out about freeze-dried cell-free technology, a core aspect of the BioBits kit, see the core Cell paper here by Pardee et al. from the Collins Lab at MIT.\nTo find out more about BioBits and miniPCR check their sites out here:\nhttps://www.mybiobits.org/\nhttps://www.minipcr.com/",
    theme: "iGEMers of The World",
    artwork: "iotw-ally.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-8-biotech-synbio-education-accessibility-biobits/id1552595526?i=1000508037819&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-8-biotech-synbio-education-accessibility-biobits/id1552595526?i=1000508037819&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/M2E4MDExNDEtMmRjMy00NzdjLTkwZGEtZTI2M2U4M2ViOWJm?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/2HLdzqJbT1csuNgK8HjreK?si=3c6654f8802b46d7",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-8-Biotech-and-synbio-education--accessibility--and-BioBits--Melody-chats-with-Ally-Huang-from-miniPCR-eo5u9l/a-a46u3sm",
    ximalaya: "",
    slug: "iotw-s1e8",
  },
  {
    id: 6,
    type: "iGEMers of The World",
    name: "The birth of Synthetic Biology and iGEM. Zeeshan chats with Drew Endy",
    guest: "Drew Endy",
    date: "Nov 4, 2020",
    season: 1,
    episode: 6,
    blurb:
      "Drew Endy is a member of the bioengineering faculty at Stanford University and the President of the BioBricks Foundation. He is also the co-founder of iGEM! Join us for this very exciting episode where Zeeshan talks to Drew about turning biology into an engineering discipline, the birth of SynBio and iGEM, and the future of SynBio education.",
    theme: "iGEMers of The World",
    artwork: "iotw-drew.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-7-birth-synthetic-biology-igem-zeeshan-chats/id1552595526?i=1000508037822&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-7-birth-synthetic-biology-igem-zeeshan-chats/id1552595526?i=1000508037822&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/MjE0OGQyOWYtMzVlMy00OWVjLTk2NjgtNGE1ZTJkNmQzOTFi?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/2u4IZvVHmGyvbOLDrzKsyZ?si=638f68f6051f442b",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-7-The-birth-of-Synthetic-Biology-and-iGEM--Zeeshan-chats-with-Drew-Endy-elj3v5/a-a3le1b7",
    ximalaya: "",
    slug: "iotw-s1e7",
  },
  {
    id: 5,
    type: "iGEMers of The World",
    name: "Educational Startups & Undergraduate Research. Zeeshan Chats with Betty Zhang",
    guest: "Betty Zhang",
    date: "Oct 14, 2020",
    season: 1,
    episode: 5,
    blurb:
      "Betty is an undergraduate science student studying at the University of Melbourne, Australia. She is the CEO and co-founder of the science communication and education startup, Student Science Squad. Join us as we talk about her research internships in the United States, China and Australia. As well as her motivation to start the Student Science Squad initiative to help high school students, parents and teachers during this difficult year.",
    theme: "iGEMers of The World",
    artwork: "iotw-betty.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-5-educational-startups-undergraduate-research/id1552595526?i=1000508037821&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-5-educational-startups-undergraduate-research/id1552595526?i=1000508037821&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/YjE4YmQ5NmMtMGJhZS00MDVmLWJkNzctYmNjNzZmNDdmZjBj?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/5yxAnGzyZFivscKo9Dd95u?si=9557a68b184a4d8a",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-5-Educational-Startups--Undergraduate-Research--Zeeshan-Chats-with-Betty-Zhang-el1grp/a-a3hjs6d",
    ximalaya: "",
    slug: "iotw-s1e5",
  },
  {
    id: 4,
    type: "iGEMers of The World",
    name: "International research & an iGEM hydrogen energy startup. Zeeshan chats with Kerstin",
    guest: "Kerstin Petroll",
    date: "Oct 7, 2020",
    season: 1,
    episode: 4,
    blurb:
      "Kerstin is an entrepreneur and postdoctoral researcher in synthetic biology at Macquarie University, Australia. The iGEM startup that Kerstin works with, HydGene renewables, have engineered designer microbes that can produce hydrogen and renewable energy. ",
    theme: "iGEMers of The World",
    artwork: "iotw-kerstin.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-4-international-research-igem-hydrogen-energy/id1552595526?i=1000508037826&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-4-international-research-igem-hydrogen-energy/id1552595526?i=1000508037826&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/MzlhOTkxNjUtY2UyZS00Njk2LWFhMTQtMzdhZTY2YjgyN2Fm?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/5AWwt6BdffROmlpRCa9pp5?si=50cfd9e2355f42f1",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-4-International-research--an-iGEM-hydrogen-energy-startup--Zeeshan-chats-with-Kerstin-ekka53/a-a3eq6cm",
    ximalaya: "",
    slug: "iotw-s1e4",
  },
  {
    id: 3,
    type: "iGEMers of The World",
    name: "iGEM APAC & Getting a Startup off the ground! Zeeshan chats with Ed Johnson",
    guest: "Ed Johnson",
    date: "Sep 30, 2020",
    season: 1,
    episode: 3,
    blurb:
      "Ed is a dual Bachelor of Commerce and Advanced Science (Biotechnology) student at University of New South Wales, Australia. He is the Head of Investments and Partnership for APAC at after iGEM. As well as the Co-founder and CEO of Negotium. Join us as we talk about iGEM EPIC, the 2019 UNSW iGEM team and how to get started with your own business!",
    theme: "iGEMers of The World",
    artwork: "iotw-ed.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-3-igem-apac-getting-startup-off-ground-zeeshan/id1552595526?i=1000508037818&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-3-igem-apac-getting-startup-off-ground-zeeshan/id1552595526?i=1000508037818&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/MmQ3YjYyOWUtMDExNS00NWMzLWFkNTAtZDQzOTljZDAxMmYx?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/1z1b9xXwSW2hQZvVP87rTF?si=826d2bc40bdc43fa",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-3iGEM-APAC--Getting-a-Startup-off-the-ground--Zeeshan-chats-with-Ed-Johnson-ek7s6f/a-a3c43bi",
    ximalaya: "https://m.ximalaya.com/keji/42254926/341156517",
    slug: "iotw-s1e3",
  },
  {
    id: 2,
    type: "iGEMers of The World",
    name: "Meet Asian (India) Ambassador, Varsha! With Zeeshan and Varsha",
    guest: "Varsha Jaisimha",
    date: "Sep 30, 2020",
    season: 1,
    episode: 2,
    blurb:
      "Varsha is a current undergraduate science student at IISER Pune, India. She is currently the Asian ambassador for iGEM 2020, as well as a member of the iGEM measurement committee. Join us as we talk about the 2019 IISER Pune team, applying to be an iGEM ambassador, and SynBio and health in India.",
    theme: "iGEMers of The World",
    artwork: "iotw-varsha.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-2-meet-asian-india-ambassador-varsha-zeeshan/id1552595526?i=1000508037823&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-2-meet-asian-india-ambassador-varsha-zeeshan/id1552595526?i=1000508037823&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw/episode/ZGNiMDUxZmUtMTVlMy00MTMzLTlmNTEtMWZhOGIxOWM0NTgw?sa=X&ved=0CAUQkfYCahgKEwiIkv700MHwAhUAAAAAHQAAAAAQxwM",
    spotify:
      "https://open.spotify.com/episode/0zLHoaxN64udHQglCpLpcV?si=da28e0bc3499449b",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-2-Meet-Asian-India-Ambassador--Varsha--With-Zeeshan-and-Varsha-ek7rsr/a-a3c41fs",
    ximalaya: "https://m.ximalaya.com/keji/42254926/341155448",
    slug: "iotw-s1e2",
  },
  {
    id: 1,
    type: "iGEMers of The World",
    name: "Community labs, Open source science and the Open Insulin movement. ",
    guest: "Alex Kelly",
    date: "Sep 11, 2020",
    season: 1,
    episode: 1,
    blurb:
      "Alex is a dual Bachelor Science (Biotechnology) and Commerce (Economics) graduate from the University of New South Wales, Australia. He is also the founder of NeoSynbio, a non-profit that aims to help eliminate the scarcity of medical supplies around the globe, as well as provide open education to an international generation of synthetic biologists. Join us as we talk about the open insulin movement, the 2017 University of Sydney iGEM team, and community science labs! Learn more about Alex here, https://www.neosynbio.com/",
    theme: "iGEMers of The World",
    artwork: "iotw-alex.jpeg",
    apple:
      "https://podcasts.apple.com/cr/podcast/episode-1-community-labs-open-source-science-open-insulin/id1552595526?i=1000508037820&l=en",
    appleEmbed:
      "https://embed.podcasts.apple.com/cr/podcast/episode-1-community-labs-open-source-science-open-insulin/id1552595526?i=1000508037820&l=en",
    google:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yZDlmZDJjNC9wb2RjYXN0L3Jzcw?sa=X&ved=0CAYQ9sEGahgKEwiIkv700MHwAhUAAAAAHQAAAAAQvQE",
    spotify:
      "https://open.spotify.com/episode/5Z6QTHLW1Rp2V03Xa2yRPs?si=WwBxVrM4SD68K6xPEYEqqQ",
    anchor:
      "https://anchor.fm/igem/episodes/Episode-1-Community-labs--Open-source-science-and-the-Open-Insulin-movement--With-Alex-Kelly-and-Zeeshan-Siddiqui-ejdt9p/a-a364l5j",
    ximalaya: "https://m.ximalaya.com/keji/42254926/341153653",
    slug: "iotw-s1e1",
  },
];

export default allEpisodes;
