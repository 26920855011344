<template>
  <div>
    <p class="text-sm tracking-wider text-gray-600 uppercase">
      {{ sectionText.byline }}
    </p>
    <h1 :class="sectionText.textClass">{{ sectionText.headline }}</h1>
    <p class="prose">{{ sectionText.paragraph }}</p>
  </div>
</template>

<script>
export default {
  name: "SectionText",
  props: {
    sectionText: {
      type: Object,
      required: true,
    },
  },
};
</script>
