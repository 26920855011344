<template>
  <iframe
    allow="autoplay *; encrypted-media *; fullscreen *"
    frameborder="0"
    height="175"
    style="
      width: 100%;
      max-width: 660px;
      overflow: hidden;
      background: transparent;
    "
    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    :src="podcast.appleEmbed"
  ></iframe>
</template>

<script>
export default {
  name: "EmbedFrame",
  props: {
    podcast: {
      type: Object,
      required: true,
    },
  },
};
</script>
