<template>
  <TopNav />
  <div>
    <div class="text-center text-red-500 wrapper">
      <h1 class="xl-headline">404</h1>
      <p class="byline">It seems you've wandered off a little bit...</p>
      <div class="py-12">
        <router-link :to="{ name: 'Home' }">
          <button class="border-red-500 btn-main hover:bg-red-500">
            Back to home
          </button>
        </router-link>
      </div>
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
export default {
  components: { TopNav, FooteriGEM },
};
</script>

<style></style>
