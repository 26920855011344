<template>
  <TopNav />
  <div class="wrapper">
    <h2 class="text-center xl-headline">
      <span class="font-extralight">Guest</span> Hosts
    </h2>
    <div v-for="host in guestHosts" :key="(host, index)">
      <HostProfile :host="host" />
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import HostProfile from "../components/HostProfile";
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
import hosts from "../data/hosts.js";
import allEpisodes from "../data/allEpisodes.js";

export default {
  name: "Home",
  components: {
    HostProfile,
    TopNav,
    FooteriGEM,
  },
  data() {
    return {
      bg_hero: {
        background:
          "url('https://static.igem.io/podcast/assets/artwork/hero-header-bg-01.svg') no-repeat center center fixed",
        backgroundSize: "cover",
      },
      podcast1: allEpisodes.filter(
        (episode) => episode.type === "#SynBio Podcast"
      )[0],
      podcast2: allEpisodes.filter(
        (episode) => episode.type === "iGEMers of The World"
      )[0],
      guestHosts: hosts.filter((host) => host.name !== "Zeeshan Siddiqui"),
      allEpisodes: allEpisodes,
    };
  },
};
</script>

<style>
.hcontainer::-webkit-scrollbar {
  display: none;
}

.hcontainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}
</style>
