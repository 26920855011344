<template>
  <div class="wrapper">
    <h3 class="pb-8 md-headline" data-aos="fade-right">Latest Episode</h3>
    <div
      class="grid items-start grid-cols-1 gap-8 md:grid-cols-3"
      data-aos="zoom-in"
    >
      <div class="sm:col-span-1">
        <div class="p-8 bg-white shadow-xl rounded-xl">
          <img
            :src="
              'https://static.igem.io/podcast/assets/artwork/guests/' +
              podcast.artwork
            "
            class="max-w-full m-auto rounded-md"
          />
        </div>
        <div class="py-4 mx-auto"><Platforms :podcast="podcast" /></div>
      </div>
      <div class="col-span-2 bg-white shadow-xl rounded-xl">
        <h3 class="px-8 pt-8 pb-2 opacity-100 sm-headline">
          S{{ podcast.season }}E{{ podcast.episode }} | {{ podcast.name }}
        </h3>
        <p class="px-8 font-serif prose">{{ podcast.date }}</p>
        <p class="px-8 pt-4 pb-8 prose-sm whitespace-pre-wrap">
          {{ podcast.blurb }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Platforms from "../components/Platforms";

export default {
  name: "LatestEpisode",
  components: { Platforms },
  props: {
    podcast: {
      type: Object,
      required: true,
    },
  },
};
</script>
