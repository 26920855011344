<template>
  <TopNav />
  <div>
    <div class="wrapper">
      <h2 class="py-12 lg-headline">All Episodes</h2>
      <div class="flex flex-row flex-wrap justify-start -m-4 place-items-start">
        <div class="m-4">
          <label for="iotw" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="iotw"
              value="iGEMers of The World"
              v-model="checkedNames"
            /><span class="filter">iGEMers of The World</span>
          </label>
        </div>
        <div class="m-4">
          <label for="htsb" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="htsb"
              value="#SynBio Podcast"
              v-model="checkedNames"
            /><span class="filter">#SynBio Podcast</span>
          </label>
        </div>
        <div class="m-4">
          <label for="computation" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="computation"
              value="Computation"
              v-model="checkedNames"
            /><span class="filter">Computational SynBio</span>
          </label>
        </div>
        <div class="m-4">
          <label for="education" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="education"
              value="Education"
              v-model="checkedNames"
            /><span class="filter">Educational SynBio</span>
          </label>
        </div>
        <div class="m-4">
          <label for="research" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="research"
              value="Research"
              v-model="checkedNames"
            /><span class="filter">Research SynBio</span>
          </label>
        </div>
        <div class="m-4">
          <label for="entrepreneurship" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="entrepreneurship"
              value="Entrepreneurship"
              v-model="checkedNames"
            /><span class="filter">Entrepreneurial SynBio</span>
          </label>
        </div>
        <div class="m-4">
          <label for="arts" class="episodeFilter">
            <input
              class="hide"
              type="checkbox"
              id="arts"
              value="Arts & Design"
              v-model="checkedNames"
            /><span class="filter">Arts & Design SynBio</span>
          </label>
          <br />
        </div>
      </div>
      <div
        class="
          grid
          items-start
          grid-cols-1
          gap-8
          py-12
          sm:gap-12
          lg:gap-48
          sm:grid-cols-2
          lg:grid-cols-3
        "
      >
        <div v-for="podcast in filteredEpisodes" :key="podcast.id">
          <div>
            <div
              data-aos="fade-up"
              @click="
                isOpen === podcast.id ? (isOpen = false) : (isOpen = podcast.id)
              "
            >
              <img
                :src="
                  'https://static.igem.io/podcast/assets/artwork/guests/' +
                  podcast.artwork
                "
                class="
                  w-3/4
                  mx-auto
                  transition
                  duration-300
                  transform
                  shadow-xl
                  cursor-pointer
                  sm:w-full
                  rounded-xl
                  hover:scale-110
                "
              />
            </div>
            <div
              class="
                flex flex-row
                justify-between
                py-4
                font-serif
                text-sm
                font-medium
                text-gray-400
              "
            >
              <h4>
                {{ podcast.type }}
              </h4>
              <h4>
                {{ podcast.date }}
              </h4>
            </div>
            <h3 class="pb-4 sm-headline">
              S{{ podcast.season }}E{{ podcast.episode }} | {{ podcast.name }}
            </h3>
            <h3 class="pb-4 font-serif text-base font-medium text-gray-400">
              featuring <span class="text-black">{{ podcast.guest }}</span>
            </h3>
          </div>
          <div v-if="isOpen === podcast.id">
            <div
              class="
                fixed
                inset-0
                z-10
                w-full
                h-full
                overflow-y-auto
                transition
                duration-300
                bg-black bg-opacity-50
                backdrop-filter backdrop-blur-lg
              "
              @click="isOpen = false"
            >
              <div
                class="
                  relative
                  z-10
                  px-4
                  mx-auto
                  my-12
                  opacity-100
                  lg:w-9/12
                  xl:w-8/12
                  2xl:w-7/12
                "
              >
                <div class="relative bg-white rounded-md shadow-lg">
                  <div class="flex justify-between">
                    <button
                      class="m-8 focus:outline-none"
                      @click="isOpen = false"
                    >
                      <svg
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                        ></path>
                      </svg>
                    </button>
                    <!-- <router-link
                      :to="{
                        name: 'EpisodePage',
                        params: { slug: podcast.slug },
                      }"
                      target="_blank"
                    >
                      <button
                        class="m-8 btn-main hover:bg-fmGreen text-fmGreen border-fmGreen"
                      >
                        Open as Page &#8599;
                      </button>
                    </router-link> -->
                  </div>
                  <div class="w-full max-w-screen-xl px-16 py-2">
                    <div class="flex flex-col justify-between sm:flex-row">
                      <div>
                        <h2
                          class="font-serif text-lg font-semibold text-gray-400"
                        >
                          {{ podcast.type }}
                        </h2>
                        <h1
                          class="
                            w-full
                            py-4
                            font-serif
                            text-2xl
                            font-bold
                            lg:text-3xl
                          "
                        >
                          S{{ podcast.season }}E{{ podcast.episode }} |
                          {{ podcast.name }}
                        </h1>
                        <p class="font-light">
                          {{ podcast.date }}
                        </p>
                      </div>
                      <div class="flex pt-8 -mx-4 place-items-center">
                        <button @click="copyURL()">
                          <img
                            src="https://static.igem.io/podcast/assets/icons/copy-link.png"
                            class="
                              w-6
                              h-6
                              m-4
                              transition
                              duration-300
                              transform
                              hover:scale-125
                            "
                            alt=""
                          />
                          <span class="hidden" id="epLink"
                            >https://igem.fm/episode/{{ podcast.slug }}</span
                          >
                        </button>
                        <a
                          :href="
                            'https://twitter.com/share?url=https://podcast.igem.org/episode/' +
                            podcast.slug +
                            '&text=Listen%20to&via=iGEMPodcasts'
                          "
                          ><img
                            src="https://static.igem.io/podcast/assets/icons/twitter_dark.svg"
                            class="
                              w-6
                              h-6
                              m-4
                              transition
                              duration-300
                              transform
                              hover:scale-125
                            "
                            alt=""
                        /></a>
                        <a
                          :href="
                            'http://www.facebook.com/sharer.php?u=https://podcast.igem.org/episode/' +
                            podcast.slug
                          "
                          ><img
                            src="https://static.igem.io/podcast/assets/icons/facebook.svg"
                            class="
                              w-6
                              h-6
                              m-4
                              transition
                              duration-300
                              transform
                              hover:scale-125
                            "
                            alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="w-full py-8 lg:w-1/2">
                      <EmbedFrame :podcast="podcast" />
                      <Platforms :podcast="podcast" />
                    </div>
                    <div class="w-full">
                      <p class="pb-12 prose whitespace-pre-line">
                        {{ podcast.blurb }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import allEpisodes from "../data/allEpisodes";
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
import Platforms from "../components/Platforms";
import EmbedFrame from "../components/EmbedFrame";

export default {
  name: "Episodes",
  components: { TopNav, FooteriGEM, EmbedFrame, Platforms },
  data() {
    return {
      isOpen: false,
      episodes: allEpisodes,
      checkedNames: [],
    };
  },
  computed: {
    filteredEpisodes: function () {
      return this.episodes.filter((episode) => {
        if (this.checkedNames.length == 0) {
          return this.episodes;
        } else {
          if (
            this.checkedNames.includes(
              "#SynBio Podcast" || "iGEMers of The World"
            )
          ) {
            return this.checkedNames.includes(episode.type);
          } else {
            return this.checkedNames.includes(episode.theme);
          }
        }
      });
    },
  },
  methods: {
    copyURL() {
      var epLink = document.getElementById("epLink").innerHTML;
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = epLink;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
  },
};
</script>
