<template>
  <TopNav />
  <div class="wrapper">
    <div class="grid items-center grid-cols-1 gap-8 pb-24 lg:grid-cols-5">
      <div class="col-span-3" data-aos="fade-right">
        <h1 class="xl-headline">
          #SynBio <span class="font-light">Podcast</span>
        </h1>
        <p class="prose">
          Synthetic biology is a broad and diverse field, it only makes sense to
          have a broad and diverse podcast. The show will have five major themes
          and will be an “audio-documentary” about the role of SynBio in
          Education, Art, Entrepreneurship, Computer Science and Research.
        </p>
      </div>
      <div
        class="order-first col-span-2 m-auto sm:order-last"
        data-aos="zoom-out"
      >
        <img
          src="https://static.igem.io/podcast/assets/logos/htsb.svg"
          class="object-center w-48 sm:w-72"
        />
      </div>
    </div>
  </div>
  <div class="wrapper max-w-screen-2xl">
    <HashtagSynBioThemes />
  </div>
  <div id="latest">
    <LatestEpisode :podcast="latestEpisode" />
  </div>
  <div class="wrapper">
    <div v-for="topic in topics" :key="topic.id" :id="topic.id" class="py-20">
      <div data-aos="fade-up">
        <img
          :src="
            'https://static.igem.io/podcast/assets/artwork/banners/' +
            topic.pattern
          "
          class="shadow-xl rounded-xl"
        />
        <div
          class="
            absolute
            inset-0
            flex flex-col
            items-center
            justify-end
            text-center
          "
        >
          <h3 class="pb-2 font-sans tracking-widest text-white">
            <span class="font-semibold">{{ topic.id }}</span> Theme
          </h3>
          <h2 class="pb-8 text-white md-headline">
            {{ topic.title }}
          </h2>
        </div>
      </div>
      <div v-for="podcast in allEpisodes" :key="podcast.id">
        <div
          class="grid grid-cols-1 gap-4 py-12 lg:grid-cols-2"
          v-if="podcast.theme == topic.theme"
        >
          <div>
            <h1 class="w-full py-4 font-serif text-xl font-semibold lg:w-3/4">
              S{{ podcast.season }}E{{ podcast.episode }} | {{ podcast.name }}
            </h1>
            <button
              class="my-8 btn-main hover:bg-fmGreen text-fmGreen border-fmGreen"
              @click="
                isOpen === podcast.id ? (isOpen = false) : (isOpen = podcast.id)
              "
            >
              See More &#8599;
            </button>
            <div v-if="isOpen === podcast.id">
              <div
                class="
                  fixed
                  inset-0
                  z-10
                  w-full
                  h-full
                  overflow-y-auto
                  transition
                  duration-300
                  bg-black bg-opacity-50
                  backdrop-filter backdrop-blur-lg
                "
                @click="isOpen = false"
              >
                <div
                  class="
                    relative
                    z-10
                    px-4
                    mx-auto
                    my-12
                    opacity-100
                    lg:w-9/12
                    xl:w-8/12
                    2xl:w-7/12
                  "
                >
                  <div class="relative bg-white rounded-md shadow-lg">
                    <div class="flex justify-between">
                      <button
                        class="m-8 focus:outline-none"
                        @click="isOpen = false"
                      >
                        <svg
                          class="fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                          ></path>
                        </svg>
                      </button>
                      <!-- <router-link
                      :to="{
                        name: 'EpisodePage',
                        params: { slug: podcast.slug },
                      }"
                      target="_blank"
                    >
                      <button
                        class="m-8 btn-main hover:bg-fmGreen text-fmGreen border-fmGreen"
                      >
                        Open as Page &#8599;
                      </button>
                    </router-link> -->
                    </div>
                    <div class="w-full max-w-screen-xl px-16 py-2">
                      <div class="flex flex-col justify-between sm:flex-row">
                        <div>
                          <h2
                            class="
                              font-serif
                              text-lg
                              font-semibold
                              text-gray-400
                            "
                          >
                            {{ podcast.type }}
                          </h2>
                          <h1
                            class="
                              w-full
                              py-4
                              font-serif
                              text-2xl
                              font-bold
                              lg:text-3xl
                            "
                          >
                            S{{ podcast.season }}E{{ podcast.episode }} |
                            {{ podcast.name }}
                          </h1>
                          <p class="font-light">
                            {{ podcast.date }}
                          </p>
                        </div>
                        <div class="flex pt-8 -mx-4 place-items-center">
                          <button @click="copyURL()">
                            <img
                              src="https://static.igem.io/podcast/assets/icons/copy-link.png"
                              class="
                                w-6
                                h-6
                                m-4
                                transition
                                duration-300
                                transform
                                hover:scale-125
                              "
                              alt=""
                            />
                            <span class="hidden" id="epLink"
                              >https://igem.fm/episode/{{ podcast.slug }}</span
                            >
                          </button>
                          <a
                            :href="
                              'https://twitter.com/share?url=https://podcast.igem.org/episode/' +
                              podcast.slug +
                              '&text=Listen%20to&via=iGEMPodcasts'
                            "
                            ><img
                              src="https://static.igem.io/podcast/assets/icons/twitter_dark.svg"
                              class="
                                w-6
                                h-6
                                m-4
                                transition
                                duration-300
                                transform
                                hover:scale-125
                              "
                              alt=""
                          /></a>
                          <a
                            :href="
                              'http://www.facebook.com/sharer.php?u=https://podcast.igem.org/episode/' +
                              podcast.slug
                            "
                            ><img
                              src="https://static.igem.io/podcast/assets/icons/facebook.svg"
                              class="
                                w-6
                                h-6
                                m-4
                                transition
                                duration-300
                                transform
                                hover:scale-125
                              "
                              alt=""
                          /></a>
                        </div>
                      </div>
                      <div class="w-full py-8 lg:w-1/2">
                        <EmbedFrame :podcast="podcast" />
                        <Platforms :podcast="podcast" />
                      </div>
                      <div class="w-full">
                        <p class="pb-12 prose whitespace-pre-line">
                          {{ podcast.blurb }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <EmbedFrame :podcast="podcast" />
            <div class="flex flex-row pt-12 justify-evenly">
              <a :href="podcast.google" target="_blank"
                ><img
                  src="https://static.igem.io/podcast/assets/badges/google-podcasts-badge.png"
                  class="
                    h-10
                    transition
                    duration-300
                    transform
                    hover:scale-110
                  "
              /></a>
              <a :href="podcast.spotify" target="_blank"
                ><img
                  src="https://static.igem.io/podcast/assets/badges/spotify-badge.svg"
                  class="
                    h-10
                    transition
                    duration-300
                    transform
                    hover:scale-110
                  "
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import HashtagSynBioThemes from "../components/HashtagSynBioThemes";
import allEpisodes from "../data/allEpisodes.js";
import EmbedFrame from "../components/EmbedFrame";
import LatestEpisode from "../components/LatestEpisode";
import topics from "../data/topics.js";
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
// import EpisodeModal from "../components/EpisodeModal";

export default {
  name: "HashtagSynBio",
  components: {
    HashtagSynBioThemes,
    LatestEpisode,
    TopNav,
    FooteriGEM,
    EmbedFrame,
  },
  data() {
    return {
      isOpen: false,
      topics: topics,
      noEpisodes: true,
      allEpisodes: allEpisodes.filter(
        (episode) => episode.type === "#SynBio Podcast"
      ),
      latestEpisode: allEpisodes.filter(
        (episode) => episode.type === "#SynBio Podcast"
      )[0],
    };
  },
};
</script>
