import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import GuestHosts from "../views/GuestHosts.vue";
import HashtagSynBio from "../views/HashtagSynBio.vue";
import iGEMersOfTheWorld from "../views/iGEMersOfTheWorld.vue";
import Episodes from "../views/Episodes.vue";
import Error404 from "../views/Error404.vue";
import EpisodePage from "../views/EpisodePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/Guest-Hosts",
    name: "GuestHosts",
    component: GuestHosts,
  },
  {
    path: "/Hashtag-SynBio",
    name: "HashtagSynBio",
    component: HashtagSynBio,
  },
  {
    path: "/iGEMers-Of-The-World",
    name: "iGEMersOfTheWorld",
    component: iGEMersOfTheWorld,
  },
  {
    path: "/Episodes",
    name: "Episodes",
    component: Episodes,
  },
  {
    path: "/episode/:slug",
    name: "EpisodePage",
    component: EpisodePage,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "Error404",
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

require.context("../", true, /\.png$/);
export default router;
