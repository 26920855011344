<template>
  <router-view />
</template>

<script>
export default {
  data: function () {
    return {
      active: false,
    };
  },
  computed: {
    currentRoute: function () {
      return this.$route.name;
    },
  },
  created: function () {},
  methods: {
    toggle: function () {
      this.active = !this.active;
    },
  },
};
</script>
