<template>
  <div class="grid grid-cols-1 gap-8 py-12 wrapper md:grid-cols-2">
    <div
      class="order-last text-center md:order-first md:text-left"
      data-aos="fade-up"
    >
      <h3 class="md-headline">{{ host.name }}</h3>
      <h5 class="py-4 byline-sm">{{ host.title }}</h5>
      <div
        class="
          flex flex-row flex-wrap
          py-4
          space-x-4
          justify-evenly
          md:justify-start
        "
      >
        <div class="flex items-center space-x-2">
          <img
            src="https://static.igem.io/podcast/assets/icons/globe.svg"
            class="w-6 h-6"
          />
          <p class="prose">{{ host.region }}</p>
        </div>
        <div class="flex items-center space-x-2">
          <img
            src="https://static.igem.io/podcast/assets/icons/pin.svg"
            class="w-6 h-6"
          />
          <p class="prose">{{ host.country }}</p>
        </div>
      </div>
      <h4 class="pt-12 pb-6 text-left xs-headline">
        {{ host.paragraphTitle }}
      </h4>
      <p class="prose text-left">
        {{ host.description }}
      </p>
    </div>
    <div class="place-self-center" data-aos="zoom-out">
      <img
        :src="
          'https://static.igem.io/podcast/assets/artwork/hosts/' + host.image
        "
        class="object-center w-64 h-64 sm:w-80 sm:h-80"
      />
      <div class="flex py-12 place-items-center justify-evenly">
        <a :href="host.twitter">
          <img
            src="https://static.igem.io/podcast/assets/icons/twitter_dark.svg"
            class="w-10 h-10 transition duration-300 transform hover:scale-125"
          />
        </a>
        <a :href="host.linkedin">
          <img
            src="https://static.igem.io/podcast/assets/icons/linkedin.svg"
            class="w-8 h-8 transition duration-300 transform hover:scale-125"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HostProfile",
  props: {
    host: {
      type: Object,
      required: true,
    },
  },
};
</script>
