<template>
  <TopNav />
  <div>
    <div class="wrapper">
      <div class="grid grid-cols-1 gap-12 py-20 sm:grid-cols-2">
        <div data-aos="fade-right" class="order-2 sm:order-1">
          <SectionText :sectionText="ourStory" />
        </div>
        <div class="order-1 place-self-center sm:order-2" data-aos="zoom-in">
          <img
            src="https://static.igem.io/podcast/assets/icons/mic.svg"
            class="w-32 sm:w-40"
          />
        </div>
        <div class="order-3 place-self-center">
          <img
            src="https://static.igem.io/podcast/assets/icons/soundwaves.svg"
            class="w-32 sm:w-40"
            data-aos="zoom-in"
          />
        </div>
        <div data-aos="fade-left" class="order-4 whitespace-pre-wrap">
          <SectionText :sectionText="whereHeaded" />
        </div>
      </div>
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import SectionText from "../components/SectionText";
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
export default {
  name: "About",
  components: { TopNav, FooteriGEM, SectionText },
  data() {
    return {
      ourStory: {
        headline: "Our Story",
        textClass: "lg-headline text-fmGreen",
        paragraph:
          "The iGEM podcasts initiative was born during the weekly global ambassadors meeting in August 2020. We were all brainstorming ideas on how to grow our community. Many iGEMers are just starting their professional careers, and hence will be part of the team of scientists that will shape the future of synthetic biology. The opportunity to connect with alumni, converse about Synbio and iGEM in a creative and focussed manner, as well as attract new audiences to the field of Synbio, is the next step in engaging and growing our community.",
      },
      whereHeaded: {
        headline: "Where are we headed?",
        textClass: "lg-headline text-fmGreen",
        paragraph:
          "We in iGEM believe in dreaming BIG: We want this podcast to develop into a full editorial and have blog posts, videos and a magazine. The aim is to develop this initiative into the Discovery Science or Natgeo of Synthetic Biology. Massive goals but we must start somewhere. No goal is too big for an iGEMer!",
      },
    };
  },
};
</script>
