<template>
  <TopNav />
  <div :style="bg_hero">
    <div class="py-20 wrapper">
      <div class="grid grid-cols-1 gap-x-12 gap-y-4 lg:grid-cols-2">
        <div>
          <h1 class="xl-headline">
            <span class="font-light">iGEM</span> Podcasts
          </h1>
          <h2 class="w-full pb-8 font-serif text-lg font-light">
            Immerse yourself in the audio documentary, where multiple layers of
            synthetic biology are explored through exciting stories.
          </h2>
          <router-link :to="{ name: 'Episodes' }">
            <button
              class="mb-8 text-fmGreen border-fmGreen btn-main hover:bg-fmGreen"
            >
              Listen to the latest episode
            </button>
          </router-link>
          <div class="w-full py-4">
            <div class="flex justify-start space-x-4 flex-nowrap">
              <a
                href="https://podcasts.apple.com/in/podcast/hashtagsynbio/id1566549358"
                target="_blank"
                ><img
                  src="https://static.igem.io/podcast/assets/badges/apple-podcasts-badge.svg"
                  class="h-10"
              /></a>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy81OWVkZDE4Yy9wb2RjYXN0L3Jzcw"
                target="_blank"
                ><img
                  src="https://static.igem.io/podcast/assets/badges/google-podcasts-badge.png"
                  class="h-10"
              /></a>
              <a
                href="https://open.spotify.com/show/3YLYcNHpMBtIRNZaXZXd0a"
                target="_blank"
                ><img
                  src="https://static.igem.io/podcast/assets/badges/spotify-badge.svg"
                  class="h-10"
              /></a>
              <!-- <a href="https://anchor.fm/zeeshan-siddiqui4" target="_blank"
            ><img src="https://static.igem.io/podcast/assets/icons/anchor.png" class="h-10 rounded-lg"
          /></a> -->
            </div>
          </div>
        </div>
        <div class="py-12">
          <div class="aspect-h-9 aspect-w-16">
            <iframe
              class="rounded-xl"
              sandbox="allow-same-origin allow-scripts allow-popups"
              title="iGEM Podcasts Trailer"
              src="https://video.igem.org/videos/embed/80b4c611-c867-4b00-94f0-94ebca987df3?title=0&warningTitle=0&peertubeLink=0"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div>
        <div class="pb-4 text-sm font-semibold text-gray-600 uppercase">
          Check out the latest episodes
        </div>
        <div
          class="p-4 hcontainer rounded-xl"
          style="
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);
          "
        >
          <div class="flex flex-row space-x-8 h-96">
            <div v-for="episode in allEpisodes" :key="episode.id">
              <router-link :to="'/episode/' + episode.slug">
                <div class="w-64 h-64">
                  <img
                    :src="
                      'https://static.igem.io/podcast/assets/artwork/guests/' +
                      episode.artwork
                    "
                    class="inline-block"
                  />
                  <h3
                    class="my-2 font-serif text-base font-semibold line-clamp-3"
                  >
                    S{{ episode.season }}E{{ episode.episode }} |
                    {{ episode.name }}
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-fmBlue bg-division">
    <div class="bg-white shadow-xl wrapper" data-aos="fade-left">
      <div class="grid items-start grid-cols-1 gap-12 lg:grid-cols-2">
        <div>
          <div>
            <div class="flex items-center space-x-4">
              <img
                src="https://static.igem.io/podcast/assets/logos/htsb.svg"
                class="w-16 sm:w-20"
              />
              <p class="text-sm tracking-wider text-gray-600 uppercase">
                1<sup>st</sup> podcast
              </p>
            </div>
            <h1 class="lg-headline">
              #SynBio <span class="font-extralight">Podcast</span>
            </h1>
            <p class="prose">
              Immerse yourself in the audio-documentary, where multiple layers
              of SynBio are explored through exciting stories". As SynBio is a
              broad and diverse field, it only makes sense to have a broad and
              diverse podcast. The show will have five major themes and will be
              an “audio-documentary” about the role of SynBio in Education, Art,
              Environment, Computer Science and Research.
            </p>
          </div>
          <div class="pt-8">
            <router-link :to="{ name: 'HashtagSynBio' }">
              <button
                class="text-blue-500 border-blue-500 btn-main hover:bg-blue-500"
              >
                Learn More
              </button>
            </router-link>
          </div>
        </div>
        <div><PodcastEmbed :podcast="podcast1" /></div>
      </div>
    </div>
  </div>
  <div class="bg-fmGreen bg-division">
    <div class="bg-white wrapper" data-aos="fade-right">
      <div class="grid items-start grid-cols-1 gap-24 lg:grid-cols-2">
        <div>
          <div>
            <div class="flex items-center space-x-4">
              <img
                src="https://static.igem.io/podcast/assets/logos/iotw.svg"
                class="w-16 sm:w-20"
              />
              <p class="text-sm tracking-wider text-gray-600 uppercase">
                2<sup>nd</sup> podcast
              </p>
            </div>
            <h1 class="lg-headline">
              iGEM<span class="font-extralight">ers of The World</span>
            </h1>
            <p class="prose">
              This podcast is a medium for iGEM alumni & teams to communicate
              ideas about the future of iGEM and synthetic biology to a
              worldwide audience. We want to promote discussion and conversation
              around SynBio from an iGEM perspective. We will be inviting iGEM
              alumni to share their stories and inspire future and current
              iGEMers. Topics of conversation will include how iGEM has given
              them direction and/or influenced their careers; whether that be in
              research, industry, or entrepreneurship. And what are the
              predominant ideas in engineering and designing the future of
              synthetic biology that currently occupy their mind?
            </p>
          </div>
          <div class="pt-8">
            <router-link :to="{ name: 'iGEMersOfTheWorld' }">
              <button
                class="text-green-500 border-green-500 btn-main hover:bg-green-500"
              >
                Learn More
              </button>
            </router-link>
          </div>
        </div>
        <div><PodcastEmbed :podcast="podcast2" /></div>
      </div>
    </div>
  </div>
  <div class="bg-white bg-division">
    <div class="wrapper">
      <h2 class="text-center lg-headline">
        <span class="font-extralight">Meet your</span> Host
      </h2>
    </div>
    <div v-for="host in mainHost" :key="(host, index)">
      <HostProfile :host="host" />
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import PodcastEmbed from "../components/PodcastEmbed";
import HostProfile from "../components/HostProfile";
import TopNav from "../components/TopNav";
import FooteriGEM from "../components/FooteriGEM";
import hosts from "../data/hosts.js";
import allEpisodes from "../data/allEpisodes.js";

export default {
  name: "Home",
  components: {
    HostProfile,
    PodcastEmbed,
    TopNav,
    FooteriGEM,
  },
  data() {
    return {
      bg_hero: {
        background:
          "url('https://static.igem.io/podcast/assets/artwork/hero-header-bg-01.svg') no-repeat center center fixed",
        backgroundSize: "cover",
      },
      podcast1: allEpisodes.filter(
        (episode) => episode.type === "#SynBio Podcast"
      )[0],
      podcast2: allEpisodes.filter(
        (episode) => episode.type === "iGEMers of The World"
      )[0],
      mainHost: hosts.filter((host) => host.name === "Zeeshan Siddiqui"),
      allEpisodes: allEpisodes,
      page: {
        title: "iGEM Podcasts",
        description: "Immerse yourself in the audio documentary, where multiple layers of synthetic biology are explored through exciting stories."
      }
    };
  },
  head() {
    return {
      title: this.page.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description,
        },
        // Open Graph
        { hid: 'og:title', property: 'og:title', content: this.page.title },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description,
        },
        // Twitter Card
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.page.title,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.page.description,
        },
      ],
    }
  },
};
</script>

<style>
.hcontainer::-webkit-scrollbar {
  display: none;
}

.hcontainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}
</style>
