<template>
  <div class="bg-gray-100">
    <div class="mx-auto max-w-7xl">
      <div
        class="
          grid grid-cols-1
          gap-8
          px-8
          py-12
          bg-gray-100
          md:place-items-center
          md:grid-cols-3
        "
      >
        <a href="mailto:podcasts.igem.org"
          ><div class="flex flex-row items-center">
            <img
              src="https://static.igem.io/podcast/assets/icons/mail.svg"
              class="w-6"
            />
            <h6 class="pl-2 font-serif font-semibold prose-lg">
              podcasts@igem.org
            </h6>
          </div></a
        >
        <a href="https://twitter.com/iGEMPodcasts">
          <div class="flex flex-row items-center">
            <img
              src="https://static.igem.io/podcast/assets/icons/twitter.svg"
              class="w-6"
            />
            <h6 class="pl-2 font-serif font-semibold prose-lg">
              @iGEMPodcasts
            </h6>
          </div>
        </a>
        <a href="http://www.linkedin.com/in/igempodcasts-zee-076710212"
          ><div class="flex flex-row items-center">
            <img
              src="https://static.igem.io/podcast/assets/icons/linkedin.svg"
              class="w-6"
            />
            <h6 class="pl-2 font-serif font-semibold prose-lg">
              LinkedIn Page
            </h6>
          </div></a
        >
      </div>
    </div>

    <div
      class="
        grid
        items-start
        grid-cols-1
        gap-8
        px-8
        py-12
        mx-auto
        max-w-7xl
        md:grid-cols-4
      "
    >
      <div>
        <img
          class="w-32 md:mx-auto"
          src="https://static.igem.io/podcast/assets/logos/iGEM_black.png"
        />
      </div>
      <div>
        <h6 class="footer-headline">iGEM Foundation</h6>
        <p class="text-xs">
          The International Genetically Engineered Machine (iGEM) Foundation is
          an independent, non-profit organization dedicated to the advancement
          of synthetic biology, education and competition, and the development
          of an open community and collaboration.
        </p>
        <br />
        <p class="text-xs">
          Visit us at
          <a href="https://igem.org"
            ><span class="text-blue-500">igem.org</span></a
          >.
        </p>
      </div>
      <div>
        <h6 class="footer-headline">Contact Us</h6>
        <div class="text-xs">
          <p><a href="mailto:hq@igem.org">hq@igem.org</a></p>
          <br />
          <p>45 Prospect Street Cambridge, MA 02139</p>
          <br />
          <p>
            Due to COVID-19, iGEM staff are working remotely and cannot receive
            phone calls. Please
            <a href="mailto:hq@igem.org"
              ><span class="text-blue-500">contact us</span></a
            >
            by email.
          </p>
          <br />
          <p>
            You can read about our privacy policy
            <a href="https://igem.org/Privacy_Policy"
              ><span class="text-blue-500">here.</span></a
            >
          </p>
          <br />
        </div>
      </div>
      <div>
        <h6 class="footer-headline">Keep in Touch!</h6>
        <div class="flex flex-row items-center space-x-4">
          <a href="https://www.instagram.com/igem_hq/"
            ><img
              src="https://static.igem.io/podcast/assets/icons/instagram.svg"
              class="w-8"
          /></a>
          <a href="https://twitter.com/igem"
            ><img
              src="https://static.igem.io/podcast/assets/icons/twitter_dark.svg"
              class="w-8"
          /></a>
          <a href="https://www.facebook.com/iGEMFoundation/"
            ><img
              src="https://static.igem.io/podcast/assets/icons/facebook.svg"
              class="w-4"
          /></a>
          <a
            href="https://join.slack.com/t/igem2021global/shared_invite/zt-nm7q5pet-onh1OhigrujAUKfX4lDWLg"
            ><img
              src="https://static.igem.io/podcast/assets/icons/slack.svg"
              class="w-8"
          /></a>
        </div>
        <h6 class="pt-8 footer-headline">
          Stay up to date with our newsletter
        </h6>
        <a href="https://confirmsubscription.com/h/i/F2D48D61C2EA742F"
          ><button
            class="text-xs normal-case border-black btn-main hover:bg-black"
          >
            Subscribe
          </button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooteriGEM",
};
</script>
