<template>
  <div
    class="p-8 shadow-xl bg-clip-padding rounded-xl"
    style="background: rgba(255, 255, 255, 0.3); backdrop-filter: blur(5px)"
  >
    <div class="flex justify-start">
      <h3 class="pb-8 opacity-100 sm-headline">
        S{{ podcast.season }}E{{ podcast.episode }} | {{ podcast.name }}
      </h3>
      <!-- <router-link
        :to="{
          name: 'EpisodePage',
          params: { slug: podcast.slug },
        }"
        target="_blank"
      >
        <button
          class="w-8 h-8 text-base transition duration-300 border-2 rounded-full text-fmGreen border-fmGreen hover:text-white hover:bg-fmGreen focus:outline-none"
        >
          &#8599;
        </button>
      </router-link> -->
    </div>
    <div class="pb-8"><EmbedFrame :podcast="podcast" /></div>
    <div class="pb-8"><Platforms :podcast="podcast" /></div>
  </div>
</template>

<script>
// import { format } from "date-fns";
import Platforms from "../components/Platforms";
import EmbedFrame from "../components/EmbedFrame";

export default {
  name: "PodcastEmbed",
  components: { Platforms, EmbedFrame },
  props: {
    podcast: {
      type: Object,
      required: true,
    },
  },
};
</script>
