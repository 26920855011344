<template>
  <head>
    <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta
      name="description"
      content="Immerse yourself in the audio documentary, where multiple layers of synthetic biology are explored through exciting stories.
"
    />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <!-- Primary Meta Tags -->
    <title>iGEM 2021 Giant Jamboree</title>
    <meta name="title" content="iGEM Podcasts" />

    <!-- Twitter -->
    <meta property="twitter:card" content="summary_large_image" />
    <meta
      property="twitter:url"
      :content="'https://podcast.igem.org/episode/' + podcast.slug"
    />
    <meta
      property="twitter:title"
      :content="
        'S' + podcast.season + 'E' + podcast.episode + ' | ' + podcast.type
      "
    />
    <meta property="twitter:description" content="" />
    <meta
      property="twitter:image"
      :content="
        'https://static.igem.io/podcast/assets/artwork/guests/' +
        podcast.artwork
      "
    />
  </head>

  <TopNav />
  <div class="wrapper">
    <div class="flex flex-col justify-between sm:flex-row">
      <div>
        <h2 class="font-serif text-lg font-semibold text-gray-400">
          {{ podcast.type }}
        </h2>
        <h1 class="w-3/4 py-4 font-serif text-3xl font-bold">
          S{{ podcast.season }}E{{ podcast.episode }} | {{ podcast.name }}
        </h1>
        <p class="font-light">
          {{ podcast.date }}
        </p>
      </div>
      <div class="flex pt-8 -mx-4 place-items-center">
        <button @click="copyURL()">
          <img
            src="https://static.igem.io/podcast/assets/icons/copy-link.png"
            class="
              w-6
              h-6
              m-4
              transition
              duration-300
              transform
              hover:scale-125
            "
            alt=""
          />
          <span class="hidden" id="epLink"
            >https://igem.fm/episode/{{ podcast.slug }}</span
          >
        </button>
        <a
          :href="
            'https://twitter.com/share?url=https://podcast.igem.org/episode/' +
            podcast.slug +
            '&text=Listen%20to&via=iGEMPodcasts'
          "
          ><img
            src="https://static.igem.io/podcast/assets/icons/twitter_dark.svg"
            class="
              w-6
              h-6
              m-4
              transition
              duration-300
              transform
              hover:scale-125
            "
            alt=""
        /></a>
        <a
          :href="
            'http://www.facebook.com/sharer.php?u=https://podcast.igem.org/episode/' +
            podcast.slug
          "
          ><img
            src="https://static.igem.io/podcast/assets/icons/facebook.svg"
            class="
              w-6
              h-6
              m-4
              transition
              duration-300
              transform
              hover:scale-125
            "
            alt=""
        /></a>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12 py-12 lg:grid-cols-2">
      <div>
        <EmbedFrame :podcast="podcast" />
        <Platforms :podcast="podcast" />
      </div>
      <div>
        <p class="pb-12 prose whitespace-pre-wrap">{{ podcast.blurb }}</p>
      </div>
    </div>
  </div>
  <FooteriGEM />
</template>

<script>
import allEpisodes from "../data/allEpisodes.js";
import EmbedFrame from "../components/EmbedFrame.vue";
import Platforms from "../components/Platforms.vue";
import TopNav from "../components/TopNav.vue";
import FooteriGEM from "../components/FooteriGEM.vue";

export default {
  name: "EpisodePage",
  data() {
    return {
      allEpisodes: allEpisodes,
    };
  },
  computed: {
    podcast() {
      return allEpisodes.find((podcast) => podcast.slug === this.slug);
    },
  },
  components: { TopNav, FooteriGEM, EmbedFrame, Platforms },
  props: {
    slug: {
      required: true,
    },
  },
  methods: {
    copyURL() {
      var epLink = document.getElementById("epLink").innerHTML;
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = epLink;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
  },
};
</script>
